<template>
  <v-btn
    @click="$emit('click')"
    large
    color="primary"
    class="flat"
    v-bind="$attrs"
  >
    <span class="icon" v-if="!!this.$slots.icon">
      <slot name="icon" />
    </span>
    <span class="content tw-font-montserrat-semi-bold" v-if="!!this.$slots.default">
      <slot />
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'FlatButton',
};
</script>

<style lang="scss" scoped>
.flat {
  border-radius: 104px;
  text-transform: none;
  margin: 0px;
  height: 54px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;


  .icon {
     margin-right: 10px;
  }
}
</style>
